export const deficiencyReporter = {
  steps: [
    {
      label: "Standort bestimmen",
      description:
        "Sie können den Standort der Mängel in einer der drei unten aufklappbaren Möglichkeiten an uns übermitteln.",
      options: [
        {
          title: "Adresseingabe",
          zipLabel: "Postleitzahl",
          zipPlaceholder: "Bitte geben Sie hier die Postleitzahl ein",
          locationLabel: "Ort",
          locationPlaceholder: "Bitte geben Sie hier den Ortsnamen ein",
          streetLabel: "Straße",
          streetPlaceholder: "Angabe des Straßennamens",
          streetNumberLabel: "Nr.",
          streetNumberPlaceholder: "Nr.",
        },
        { title: "Standort über Karte bestimmen" },
        { title: "aktuellen Standort ermitteln" },
      ],
    },
    {
      label: "Mängel beschreiben",
      categoryLabel: "Mängel-Kategorie auswählen*",
      categories: {
        placeholder: "Bitte auswählen",
        default: "default",
        others: [
          "Beschilderung, Verkehrszeichen",
          "Parkplätze/Parkhäuser",
          "Straßen, Rad- und Gehwege",
          "Gewässer, Durchlässe, Einläufe",
          "Bürger- und Vereinshäuser",
          "Wilde Müllablagerungen",
          "Abfallbehälter/Glascontainer",
          "Öffentliche WC-Anlagen",
          "Parkanlagen",
          "Spielplätze, Sportanlagen, Freizeitanlagen",
          "Straßenbeleuchtung",
          "Sonstiges",
        ],
      },
      description: "Beschreibung (max. 2000 Zeichen)*",
      descriptionPlaceholder: "Beschreiben Sie bitte die Mängel...",
      descriptionMaxChars: 2000,
      pictureUpload: "Bild(er) hochladen (optional, je Bild max.10MB):",
      pictureChange: "Bild(er) bearbeiten (optional, je Bild max.10MB):",
      pictureRemove: "Bild löschen",
      pictureButtonText: "Bilder auswählen",
    },
    {
      label: "Ihre Kontaktdaten",
      infoText:
        "Sollten wir weitere Rückfragen haben, würden wir Sie bitten eine E-Mail-Adresse sowie Telefonnummer (optional) zu hinterlegen.",
      emailLabel: "E-Mail eingeben*",
      emailPlaceholder: "Bitte geben Sie Ihr E-Mail-Adresse ein",
      phoneLabel: "Telefonnummer eingeben",
      phonePlaceholder: "Bitte geben Sie Ihre Telefonnummer ein",
      dataHandling: "Ich bin damit einverstanden, dass meine Daten zum Zwecke der Kontaktaufnahme verarbeitet werden.",
      privacyPolicy: "Ich habe die Datenschutzerklärung gelesen und verstanden.",
    },
  ],
  finalStep: {
    thanks: "Vielen Dank!",
    deficiencySent: "Die Meldung wurde erfolgreich weitergeleitet",
  },
  continue: "Weiter",
  back: "Zurück",
  cancel: "Abbrechen",
  send: "Mängel übermitteln",
  close: "Schliessen",
  default: {
    activeStep: -1,
    positionOverlay: false,
    streetName: "",
    streetNumber: "",
    zipCode: "",
    locationName: "",
    location: null,
    category: "",
    description: "",
    email: "",
    phoneNumber: "",
    images: [],
    dataHandling: false,
    privacyPolicy: false,
    triggerCenter: true,
  },
};
